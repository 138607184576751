import React, { useContext, useEffect } from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { JsonLd } from 'react-schemaorg'

// Import Components
import {
	EmailSubscribeContext,
	GtmDataLayerSetup,
	Layout,
	OrganizationSchema,
	QtWidget,
	SearchSchema,
	SiteConfigContext,
} from '@jelly/components'
import { HubCard, HubLinks, Sidebar } from '../components/HomePage'

const SAME_AS_SCHEMA_URLS = [
	'https://www.linkedin.com/company/businessnews-daily/',
	'https://www.facebook.com/BusinessNewsDaily',
	'https://twitter.com/BNDarticles',
]

const HomeWrapper = styled.div`
	height: 100%;
	width: 100%;
`
const ContentWrapper = styled.div`
	${({ theme }) => theme.maxWidth.L};
	display: flex;
	flex-wrap: wrap;
	height: auto;
	justify-content: center;
	margin: 0 auto;
	overflow: hidden;
	padding: 1rem;

	${({ theme }) => theme.MQ.M} {
		flex-wrap: nowrap;
		padding: 1rem 2rem;
	}
`
const MainContent = styled.div`
	background-color: white;
	width: 100%;

	${({ theme }) => theme.MQ.M} {
		order: 2;
		width: 900px;
	}
`

function HomePage({ data }) {
	const {
		siteConstants: {
			EMAIL_CAPTURE_SOURCES: { homepage },
			HUB_LINKS,
			META_DATA,
		},
	} = useContext(SiteConfigContext)

	const { emailSubscribeFormSubmitted, setEmailSubscribeSource, setIsScrollListenerEnabled } = useContext(
		EmailSubscribeContext
	)

	useEffect(() => {
		setEmailSubscribeSource(homepage)
	}, [homepage, setEmailSubscribeSource])

	useEffect(() => {
		// Enable scroll listener here so the Email Capture Floater Bar pops up on scroll
		!emailSubscribeFormSubmitted && setIsScrollListenerEnabled(true)

		// Clean up after ourselves
		return () => {
			setIsScrollListenerEnabled(false)
		}
	}, [emailSubscribeFormSubmitted, setIsScrollListenerEnabled])
	const sections = Object.values(data)
	const featuredSection = sections[0]
	const hubs = sections.slice(1, 6)
	const hubLinks = HUB_LINKS.concat({
		fullTitle: 'Build Your Career',
		title: 'Build',
		slug: 'build-your-career',
		subHubs: [
			{ title: 'Get the Job', slug: 'get-the-job' },
			{ title: 'Get Ahead', slug: 'get-ahead' },
			{ title: 'Office Life', slug: 'office-life' },
			{ title: 'Work-Life Balance', slug: 'work-life-balance' },
			{ title: 'Home Office', slug: 'home-office' },
		],
	})

	return (
		<HomeWrapper>
			<GtmDataLayerSetup contentType="homepage" templateType="list" />
			<JsonLd
				item={OrganizationSchema('https://www.businessnewsdaily.com', META_DATA.ogImage, SAME_AS_SCHEMA_URLS)}
			/>
			<JsonLd item={SearchSchema('https://www.businessnewsdaily.com')} />
			<Layout
				inverseLinkColors={true}
				meta={{ ...META_DATA, canonical: 'https://www.businessnewsdaily.com', robots: 'index, follow' }}
			>
				<main>
					<ContentWrapper>
						<Sidebar />
						<MainContent>
							<HubCard
								content={featuredSection.hits}
								hasTopBorder={false}
								name="See All Latest"
								slug="latest"
							/>
							<QtWidget qtOfferGroupId="2" />

							{hubLinks.map((hub, i) => {
								return (
									<HubCard
										content={hubs[i].hits} // get the array of content for each hub
										key={`card-${i}`}
										name={hub.fullTitle}
										slug={hub.slug}
									/>
								)
							})}
						</MainContent>
					</ContentWrapper>
					<HubLinks hubs={hubLinks} />
				</main>
			</Layout>
		</HomeWrapper>
	)
}

export default HomePage

export const HomepageContentQuery = graphql`
	fragment HubContent on ContentSearchOutput {
		hits {
			_source {
				href
				title
				displayModified
				thumbnail {
					path
				}
				channels {
					primary {
						name
						slug
					}
				}
			}
		}
	}
	query LatestContentByHubQuery {
		featured: content(
			limit: 4
			sort: displayModified__desc
			query: { terms: { docType__keyword: [{ value: "article" }, { value: "category" }] } }
		) {
			...HubContent
		}
		start: content(
			limit: 5
			query: { bool: { should: { terms: { channels__primary__slug__keyword: "start-your-business" } } } }
			sort: displayModified__desc
		) {
			...HubContent
		}
		grow: content(
			limit: 5
			query: { bool: { should: { terms: { channels__primary__slug__keyword: "grow-your-business" } } } }
			sort: displayModified__desc
		) {
			...HubContent
		}
		lead: content(
			limit: 5
			query: { bool: { should: { terms: { channels__primary__slug__keyword: "lead-your-team" } } } }
			sort: displayModified__desc
		) {
			...HubContent
		}
		find: content(
			limit: 5
			query: { bool: { should: { terms: { channels__primary__slug__keyword: "find-a-solution" } } } }
			sort: displayModified__desc
		) {
			...HubContent
		}
		build: content(
			limit: 5
			query: { bool: { should: { terms: { channels__primary__slug__keyword: "build-your-career" } } } }
			sort: displayModified__desc
		) {
			...HubContent
		}
	}
`
